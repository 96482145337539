<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 用户管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.name" placeholder="请输入关键词" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handlAdd">添加</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="openAddTol">批量添加</el-button>
        <el-button type="text" style="margin-left: 30px" @click="ResetSearch">重置</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
          height="500"
      >
        <el-table-column prop="Name" label="姓名" width="180" :required="true"></el-table-column>
        <el-table-column prop="Email" label="电子邮箱"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column prop="TotalIntegral" label="总贡献值" align="center"></el-table-column>
        <el-table-column prop="NotIntegral" label="待归属" align="center"></el-table-column>
        <el-table-column prop="UseIntegral" label="已归属" align="center"></el-table-column>
        <el-table-column width="100" label="操作" align="center">
          <template #default="scope">
            <el-button
                type="text"
                @click="handleEdit( scope.row)"
            >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 修改个人信息-->
    <el-dialog title="操作" v-model="edit.user" width="670px">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="姓名" :required="true">
          <el-input v-model="form.Name" :disabled="style=='编辑'"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱">
          <el-input v-model="form.Email" :disabled="style!='编辑'"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :required="true">
          <el-input v-model="form.Mobile" :disabled="style=='编辑'"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="edit.user = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">保存</el-button>
                </span>
      </template>
    </el-dialog>
<!--    批量添加用户-->
    <AddTol ref="AddTol"></AddTol>
  </div>
</template>

<script>
import {fetchData, EditData, regexfetchData, AddData} from "../../api/User";
import AddTol from "./com/AddTol";


export default {
  data() {
    return {
      style: '添加',
      query: {
        name: "",
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      loading: true,
      tableData: [],
      edit: {
        user: false,
      },

      form: {},
    };
  },
  components:{
    AddTol
  },
  created() {
    this.getData();
  },
  methods: {
    openAddTol(){
      this.$refs.AddTol.open()
    },
    handlAdd() {
      this.edit.user = true
      this.style = '添加'
    },
    ResetSearch() {
      this.query.pageIndex = 1
      this.query.name = ''
      this.getData()
    },
    handleSearch() {
      let name = this.query.name
      this.tableData = []
      this.loading = true
      this.query.pageTotal = 0
      this.query.pageIndex = 1
      regexfetchData({name}).then(res => {
        this.loading = false
        let data = res.sum
        this.tableData = data
      })
    },
    getData() {
      this.loading = true
      fetchData({
        skip: this.query.pageIndex
      }).then(res => {
        let bindata = res.sum;
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
        this.loading = false
      });
    },
    // 打开编辑
    handleEdit(row) {
      this.style = '编辑'
      this.form = {
        ...row
      };
      this.edit.user = true;
    },

    // 保存编辑
    saveEdit() {
      if (this.style=='编辑'){
        EditData( {...this.form}).then(res => {
          if (res.code == 0) {
            this.getData();
            this.edit.user = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
      }else {
        AddData( {...this.form}).then(res=>{
          if (res.code == 0) {
            this.getData();
            this.edit.user = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        })
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    },
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
