<template>
  <div>
    <el-dialog v-model="view" width="900px" :close-on-click-modal="false"
               :close-on-press-escape="false" :show-close="false">
      <div class="box">
        <el-button type="default" @click="exports" class="inFoExit">导出EXCEL模板</el-button>
        <el-upload
            accept=".xlsx"
            :on-success="handleSuccess"
            :show-file-list="false"
            class="inFoExit"
            :action='actionUrl'>
          <el-button type="default">导入EXCEL</el-button>
        </el-upload>
      </div>
      <el-table
          :data="tableData"
          border
          height="400"
          class="table"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
      </el-table>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="close">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">批量添加</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import {Actions, AddAlladd, AddAllExports} from "../../../api/User";

export default {
  data() {
    return {
      view: false,
      actionUrl: '',
      tableData: []
    }
  },
  mounted() {
    this.actionUrl = Actions()
  },
  methods: {
    handleSuccess(res) {
      if (res.code == 0) {
        let initData = res.initData
        this.tableData = initData
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    },
    open() {
      this.view = true
    },
    saveEdit() {
      let data = this.tableData
      this.openLoading()
      AddAlladd({data}).then(res => {
        this.closeLoading('处理中')
        if (res.code == 0) {
          this.$message.success(res.message);
          this.$alert(res.data, '系统', {
            confirmButtonText: '确定',
          });
          this.view = false
        } else {
          this.$message.error(res.message);
        }
      })
    },
    close() {
      this.view = false
    },
    //导出模板
    exports() {
      AddAllExports().then(res => {
        if (res.code == 0) {
          let file = res.file
          let files = process.env.VUE_APP_URL + file
          window.open(files)
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },

  }
}
</script>

<style scoped lang="scss">

.inFoExit {
  //display: inline-block;
}

.box {
  width: 300px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}


::v-deep .el-upload--text {
  width: auto;
  height: auto;
}
</style>